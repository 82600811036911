import React, { useRef } from "react"

import { Layout } from "@components/layout"
import {
  Hero2021,
  HeaderSlider,
  PartnersSlider,
  Social,
  Program,
  GoogleMap,
  Newsletter,
  AgeVerify,
} from "@components/standard"

const IndexPage = ({ pageContext, location }) => {
  const { page, productsWithDetails, posts } = pageContext
  return (
    <Layout {...page}>
      <HeaderSlider />
      <Hero2021 />
      <Program />
      <PartnersSlider />
      <GoogleMap />
      <Newsletter />
    </Layout>
  )
}

export default IndexPage
